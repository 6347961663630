<template>
  <a-form-model ref="form" :model="form" :rules="rules">
    <a-form-model-item label="充值卡号" prop="cardNo">
      <!-- <a-input v-model="form.cardNo" placeholder="请输入加油卡卡号" /> -->
      <a-select
        show-search
        placeholder="请输入充值卡号"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleMainCardSearch"
        @change="handleMainCardChange"
        :loading="loading"
        :disabled="isOnlyView"
        v-model="form.cardNo"
      >
        <a-select-option v-for="(d, index) in cardNoArray" :key="index">{{ d.cardNo }}</a-select-option>
      </a-select>
    </a-form-model-item>
    <!-- <a-form-model-item label="账务类型" prop="accountType">
        <a-select placeholder="请选择账务类型" v-model="form.accountType">
          <a-select-option value>请选择字典生成</a-select-option>
        </a-select>
      </a-form-model-item> -->
    <a-form-model-item label="此卡当前总余额（元）" prop="balanceMainCard">
      <a-input v-model="form.balanceMainCard" disabled placeholder="此卡当前总余额（元）" />
    </a-form-model-item>
    <a-form-model-item label="此卡当前未划拨余额（元）" prop="balanceAvailable">
      <a-input v-model="form.balanceAvailable" disabled placeholder="此卡当前未划拨余额（元）" />
    </a-form-model-item>
    <a-form-model-item label="充值发起人" prop="createBy">
      <a-input v-model="form.createBy" disabled placeholder="充值发起人" />
    </a-form-model-item>
    <a-form-model-item label="发起时间" prop="createTime">
      <a-input v-model="form.createTime" disabled placeholder="发起时间" />
    </a-form-model-item>
    <a-form-model-item label="充值金额" prop="amount">
      <a-input v-model="form.amount" :disabled="isOnlyView" placeholder="请输入充值金额" />
    </a-form-model-item>
    <a-form-model-item label="备注说明" prop="comment">
      <a-textarea v-model="form.comment" :disabled="isOnlyView" placeholder="请输入备注说明" />
    </a-form-model-item>
    <div class="bottom-control" v-if="!isOnlyView">
      <a-space>
        <a-button type="primary" @click="submitForm">保存</a-button>
        <a-button type="dashed" @click="cancel">取消</a-button>
      </a-space>
    </div>
  </a-form-model>
</template>

<script>
import { getOilAccountRecord, addOilAccountRecord, updateOilAccountRecord } from '@/api/iot/oilAccountRecord'
import { mainCardList } from '@/api/iot/oilCard'
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'CreateForm',
  props: {
    source: {
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    this.handleMainCardSearch = debounce(this.handleMainCardSearch, 500)
    return {
      loading: false,
      isOnlyView: false,
      formTitle: '',
      // 表单参数
      form: {
        cardNo: null,
        accountType: 'recharge',
        amount: null,
        amountTransfer: null,
        balanceMainCard: 0,
        balanceBranchCard: 0,
        balanceAvailable: 0,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null,
        createBy: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cardNo: [{ required: true, message: '充值卡号不能为空', trigger: 'blur' }],
        amount: [{ required: true, message: '充值金额不能为空', trigger: 'change' }]
      },
      cardNoArray: []
    }
  },
  filters: {},
  created() {},
  computed: {
    ...mapState({
      userInfo: state => state.user.info
    })
  },
  watch: {},
  mounted() {
    if (this.source === 'menu') {
      this.handleAdd()
    }
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.isOnlyView = false
      this.form = {
        cardNo: null,
        accountType: 'recharge',
        amount: null,
        amountTransfer: null,
        balanceMainCard: null,
        balanceBranchCard: null,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null,
        createBy: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(title, record) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = title
      this.initForm(record)
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOilAccountRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    initForm(record) {
      console.log('userInfo1', this.userInfo)
      if (record) {
        this.isOnlyView = true
        Object.assign(this.form, record)
        return
      }
      this.isOnlyView = false
      this.form.createBy = this.userInfo.userName
      this.form.inchargeBy = this.userInfo.userId
      const d = new Date()
      const Y = d.getFullYear()
      const M = d.getMonth() + 1
      const D = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const time = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      this.form.createTime = time
      console.log('this.form', this.form)
      this.$forceUpdate()
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.mainCardNo = this.form.cardNo
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOilAccountRecord(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addOilAccountRecord(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handleMainCardSearch(value) {
      console.log('handleMainCardSearch', value)
      const queryParam = {
        mainCardNo: value
      }
      this.loading = true
      mainCardList(queryParam).then(response => {
        this.cardNoArray = response
        this.loading = false
      })
    },
    handleMainCardChange(value) {
      console.log('handleMainCardChange', value)
      this.form.cardNo = this.cardNoArray[value].cardNo
      const tmpArr = this.cardNoArray.filter(res => res.cardNo === this.form.cardNo)
      console.log('tmpArr', tmpArr)
      if (tmpArr.length > 0) {
        this.form.balanceMainCard = tmpArr[0].balanceTotal
        this.form.balanceAvailable = tmpArr[0].balanceAvailable
      }
      console.log('this.form2', this.form)
    }
  }
}
</script>
